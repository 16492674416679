var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Dati Identificativi",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "display",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].display.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .display.label,
                                                          placeholder:
                                                            "Inserisci un codice",
                                                          rules:
                                                            _vm.getRules(
                                                              "display"
                                                            ),
                                                          disabled: true,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .display,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "display",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].display",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "title",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].title.label,
                                                          label: "Descrizione",
                                                          placeholder:
                                                            "Inserisci una descrizione",
                                                          rules: {
                                                            required: true,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .title,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].title",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "sector_id",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].sector_id.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .sector_id.label,
                                                          options:
                                                            _vm.sector_opt,
                                                          taggable: false,
                                                          multiple: false,
                                                          closeOnSelect: true,
                                                          rules:
                                                            _vm.getRules(
                                                              "sector_id"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .sector_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "sector_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].sector_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].info.label,
                                                          vid: "info",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .info.label,
                                                          rules:
                                                            _vm.getRules(
                                                              "info"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .info,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "info",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].info",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "is_sundry_expense",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].is_sundry_expense
                                                            .label,
                                                          label:
                                                            "Mostra solo in movimenti vari",
                                                          options: _vm.beForm[
                                                            _vm.rep
                                                          ].is_sundry_expense
                                                            .options || [
                                                            {
                                                              value: "N",
                                                              text: "No",
                                                            },
                                                            {
                                                              value: "Y",
                                                              text: "Sì",
                                                            },
                                                          ],
                                                          rules:
                                                            _vm.getRules(
                                                              "is_sundry_expense"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .is_sundry_expense,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "is_sundry_expense",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].is_sundry_expense",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.customInputs[_vm.rep]
                                                    .length,
                                                expression:
                                                  "customInputs[rep].length",
                                              },
                                            ],
                                            staticClass: "mt-2",
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                attrs: {
                                                  header:
                                                    "Attributi personalizzati",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("custom-inputs", {
                                                      attrs: {
                                                        customInputs:
                                                          _vm.customInputs[
                                                            _vm.rep
                                                          ],
                                                        beForm:
                                                          _vm.beForm[_vm.rep],
                                                        beRules:
                                                          _vm.beRules[_vm.rep],
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[_vm.rep],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            _vm.rep,
                                                            $$v
                                                          )
                                                        },
                                                        expression: "form[rep]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4103727675
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }